import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ProcessStatus } from '../../api';
import { Button } from '../../components/button';
import { ErrorBox } from '../../components/error-box';
import { Loader } from '../../components/loader';
import { useStore } from '../../store/store';
import {
  useCheckProcessStatus,
  useExecuteProcess,
} from '../authentication/api';
import { errorTypeMap } from '../error';
import { ROUTES } from '../routes-map';

export const FinanceDataInitRoute = () => {
    const navigate = useNavigate();
    const { userData, loginFlow, destinationKey, gjeldsConsent, sendingFlow } =
        useStore((state) => ({
            userData: state.userData,
            loginFlow: state.loginFlow,
            destinationKey: state.destinationKey,
            gjeldsConsent: state.gjeldsConsent,
            sendingFlow: state.sendingFlow,
        }));
    const executeProcess = useExecuteProcess();

    const [processStatus, setProcessStatus] =
        useState<ProcessStatus>('Created');
    const checkProcessStatus = useCheckProcessStatus();
    const [timeLeft, setTimeLeft] = useState(600000);

    const disabled = executeProcess.isLoading || processStatus !== 'Created';

    const onProcessStart = async () => {
        try {
            const processId = await executeProcess.mutateAsync({
                data: {
                    userData: {
                        personNumber: userData.personNumber,
                        phoneNumber: userData.phoneNumber,
                    },
                    destinationKey,
                    gjeldsConsent,
                    loginFlow,
                    sendingFlow,
                },
            });
 
            /**
             * If the login flow is BANKID then we need to redirect the user
             * to the manual upload page, since check process will always return
             * `Created` for BANKID.
             */
            if (loginFlow === 'BANKID') {
                navigate(
                    ROUTES.FINANCE_DATA_UPLOAD.buildPath({
                        processId,
                    }),
                );
                return;
            }

            /**
             * Check every 5 seconds if the backend has processed the
             * request key.
             * This applies only to the login flow BANKID_MOBILE.
             */
            const checkProcessStatusInterval = setInterval(async () => {
                const { state, errorCode } =
                    await checkProcessStatus.mutateAsync({
                        processId,
                    });

                if (state === 'Finished') {
                    setProcessStatus(state);
                    clearInterval(checkProcessStatusInterval);

                    navigate(
                        ROUTES.FINANCE_DATA_SUBMIT.buildPath({
                            processId,
                        }),
                    );
                }

                if (
                    state === 'Closed' ||
                    state === 'NotFound' ||
                    state === 'Unknown' ||
                    state === 'Error'
                ) {
                    setProcessStatus(state);
                    clearInterval(checkProcessStatusInterval);
                }

                if (state === 'Error') {
                    setProcessStatus(state);
                    clearInterval(checkProcessStatusInterval);

                    navigate(
                        ROUTES.ERROR_WITH_TYPE.buildPath({
                            errorType: errorCode ? errorTypeMap[errorCode] : '',
                            targetId: destinationKey,
                        }),
                    );
                }

                setTimeLeft(timeLeft - 5000);
            }, 5000);

            /**
             * If the backend does not respond within 10 minutes with the proper
             * state then display an error.
             */
            setTimeout(() => {
                clearInterval(checkProcessStatusInterval);
                setProcessStatus('Unknown');
            }, 600000);
        } catch (error) {
            console.error(error);
        }
    };

    if (checkProcessStatus.isError || executeProcess.isError) {
        return (
            <ErrorBox>
                <a href="https://www.eiendomsfinans.no/">
                    <Button variant="primary">Avslutt</Button>
                </a>
            </ErrorBox>
        );
    }

    if (executeProcess.isLoading || executeProcess.isSuccess) {
        return (
            <div style={{ textAlign: 'center' }}>
                Datainnsamling pågår. Hele prosessen tar normalt 15-20 sekunder,
                men kan ta et par minutter. Ikke lukk vinduet.
                <Loader />
            </div>
        );
    }

    // {processStatus !== 'Created' && <div>Behandler dataene dine..</div>}

    return (
        <>
            <h2>Starter datainnsamling!!</h2>
            <p>
                Du er i ferd med å starte prosessen. Finn frem telefonen din og
                klikk neste. Etter ca 15-20 sekunder får du da opp
                bank-ID-innloggingen på telefonen din. Pass på å å fullføre den.
                Og ikke lukk browservinduet mens du venter!
            </p>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '20px 0',
                }}
            >
                <Button
                    variant="secondary"
                    onClick={onProcessStart}
                    disabled={disabled}
                >
                    Fortsette
                </Button>
            </div>
        </>
    );
};
