import { useMemo } from 'react';

import { useTargetIdParam } from './use-target-id-param';
import { SendingFlow } from '../utils/types';

/**
 * If targetId is a valid uuid then it means that we need to send data
 * to the CRM, otherwise we need to send data to the email.
 */
export const useSubmissionMode = () => {
    const { isTargetIdUUID, targetId } = useTargetIdParam();

    const submissionMode: SendingFlow = useMemo(() => {
        const mode = isTargetIdUUID ? 'CRM' : 'EMAIL';
        console.warn(`Submission mode: ${mode}, target id: ${targetId}`);

        return mode;
    }, [isTargetIdUUID, targetId]);

    return { submissionMode };
};
