import S from './styles';

export const Loader = () => {
    return (
        <S.Wrapper>
            <div className="loader-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </S.Wrapper>
    );
};
