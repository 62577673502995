import { css } from '@emotion/react';

import { media } from './media';

export const globalStyles = css`
    *,
    *:after,
    *:before {
        box-sizing: border-box;
    }

    body {
        font-family: 'Source Sans Pro', sans-serif;

        ${media.max('md')} {
            font-size: 16px;
            line-height: 1.5;
        }

        ${media.min('md')} {
            font-size: 19px;
            line-height: 1.8;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.25;

        font-family: 'Playfair Display', serif;
        color: #10307b;
        margin: 0;
    }

    h1 {
        font-weight: 900;

        ${media.max('md')} {
            font-size: 32px;
        }

        ${media.min('md')} {
            font-size: 60px;
        }
    }

    h4 {
        font-weight: 400;

        ${media.max('md')} {
            font-size: 28px;
        }

        ${media.min('md')} {
            font-size: 55px;
        }
    }
`;
