import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import S from './styles';
import { ROUTES } from '../routes-map';

export const PreLoginBankId = () => {
    const navigate = useNavigate();

    return (
        <S.Wrapper>
            <S.Heading>Last opp dokumentasjon med Bank-ID</S.Heading>
            <p>
                For å raskt kunne behandle søknaden og utbetale lånet trenger vi
                og bankene vi samarbeider med dokumentasjon på søknaden din.
                Dataene vil ikke bli brukt til annet enn å dokumentere søknaden
                din, og blir behandlet i henhold til Eiendomsfinans sine
                personvernvilkår og brukervilkår
            </p>
            <S.Subheading>Bank-ID med app eller kodebrikke:</S.Subheading>
            <p>
                Ved å verifisere deg med Bank-ID kan vi hente dine data fra
                Gjeldsregisteret og Eiendomsverdi automatisk
            </p>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '20px 0',
                }}
            >
                <Button
                    variant="secondary"
                    onClick={() => navigate(ROUTES.LOGIN.path)}
                >
                    Fortsett
                </Button>
            </div>
        </S.Wrapper>
    );
};
