import { useCallback } from 'react';

import {
    advisorsEmailData,
    defaultUserEmail,
    internalEmailData,
} from '../../../data/emails';
import { devMode } from '../../../services/dev-mode';
import { useStore } from '../../../store/store';

export const useMatchEmailDestination = () => {
    const destinationKey = useStore((state) => state.destinationKey);

    const emailDestination = useCallback((targetId?: string) => {
        const emails =
            devMode === 'dev'
                ? [...internalEmailData]
                : [...internalEmailData, ...advisorsEmailData];

        const target = targetId || destinationKey;

        /**
         * Try to match targetId with predefined email adresses,
         */
        const matchingItemFromEmailList = emails.find((item) => {
            if (
                item.id === target ||
                item.id.toLowerCase() === target?.toLowerCase()
            ) {
                return item;
            }

            return false;
        });

        /**
         * If match use that email otherwise use default email
         */
        return matchingItemFromEmailList
            ? matchingItemFromEmailList.email
            : defaultUserEmail.email;
    }, []);

    return { emailDestination };
};
