import S from './styles';

interface Props
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    label: string;
}

export const FormButton = ({ label, ...restProps }: Props) => {
    return <S.Wrapper {...restProps}>{label}</S.Wrapper>;
};
