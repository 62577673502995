import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const styles = {
    Wrapper: styled.section`
        color: #858796;
        line-height: 1.5;
        margin: 0 auto;
        padding: 60px 0;
    `,

    Heading: styled.h2`
        margin: 0 0 10px;
    `,

    Subheading: styled.h4`
        font-size: 16px;
        margin: 0 0 10px;
        font-weight: 700;
    `,
};

export default styles;
