import {
  useCallback,
  useState,
} from 'react';

import { useProcessIdParam } from '../../../hooks/use-process-id-param';
import { useSendMailMutation } from '../../../queries/useSendMailMutation';
import { useStore } from '../../../store/store';

export const useSubmitEmail = () => {
    const [state, setState] = useState<
        'initial' | 'loading' | 'success' | 'error'
    >('initial');
    

    const sendMailMutation = useSendMailMutation();

    const { processId } = useProcessIdParam();

    const desinationKey = useStore(state => state.destinationKey);

    /**
     * Send the email
     */
    const sendEmail = useCallback(async () => {
        setState('loading');
        try {
            await sendMailMutation.mutateAsync({
                data: {
                    processId,
                    destination: desinationKey,
                },
            });

            setState('success');
        } catch (error) {
            setState('error');
            console.error(error);
        }
    }, [processId, desinationKey, sendMailMutation]);

    /**
     * Send the email on mount
     */
    if (state === 'initial') {
        sendEmail();
    }

    return {
        state,
    };
};
