import { ReactNode } from 'react';

import { Container } from '../container';
import { Header } from '../header';

interface LayoutProps {
    children: ReactNode;
}

export function Layout({ children }: LayoutProps) {
    return (
        <>
            <Header />
            <Container>{children}</Container>
        </>
    );
}
