import styled from '@emotion/styled';

export default {
    Form: styled.form`
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    `,

    FormItem: styled.div`
        display: block;
        label {
            display: block;
            margin: 0 0 5px;
        }

        input {
            border-radius: 0.35rem;
            width: 100%;
            color: #858796;
            border: 1px solid #d1d3e2;
            padding: 6px 12px;
            font-size: 16px;
        }
    `,

    AuthMessage: styled.div`
        text-align: center;
        text-decoration: underline;
    `,
};
