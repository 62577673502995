import { ReactNode } from 'react';

import S from './styles';

interface BoxProps {
    variant: 'primary';
    children: ReactNode;
}

export function Box({ children, variant, ...restProps }: BoxProps) {
    return (
        <S.Wrapper variant={variant} {...restProps}>
            {children}
        </S.Wrapper>
    );
}
