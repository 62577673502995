import { useNavigate, useParams } from 'react-router-dom';

import { useTargetIdParam } from '../../hooks/use-target-id-param';
import { ROUTES } from '../routes-map';
import S from './styles';
import { ErrorPage, ProcessErrorCode } from './types';

const errorDataMap: Record<ErrorPage, { description: string }> = {
    'login-timeout': {
        description:
            'Påloggingen var dessverre ikke vellykket. Prøv igjen og sørg for at du bekrefter pålogging på telefonen med Bank-ID på mobil.',
    },
    'server-error': {
        description:
            'Vi klarte ikke å hente dataene dine på systemet vårt. Vi gjør vårt ytterste for å minimere slike situasjoner. Prøv gjerne igjen! Hvis problemet vedvarer, setter vi pris på om du melder fra så vi kan finne feilen.',
    },
    'invalid-user-data': {
        description: `Systemet vårt oppdaget at dataene du sendte inn med skjemaet er ugyldige. Det kan enten være ugyldig personnummer, ugyldig telefonnummer eller du har ikke BankID mobil aktivert.`,
    },
};

export const errorTypeMap: Record<ProcessErrorCode, ErrorPage> = {
    LOGIN_TIMEOUT: 'login-timeout',
    SERVER_ERROR: 'server-error',
    INVALID_USER_DATA: 'invalid-user-data',
};

export function ErrorRoute() {
    const { errorType } = useParams<{ errorType: ErrorPage }>();
    const { targetId } = useTargetIdParam();
    const navigate = useNavigate();

    const handleTryAgain = () => {
        navigate(
            ROUTES.ERROR.buildPath({
                targetId,
            }),
        );
    };

    if (!errorType) {
        return (
            <S.Description>
                Det har oppstått en feil. Vennligst prøv igjen eller kontakt oss
                <S.TryAgainButton
                    variant="secondary"
                    onClick={() => handleTryAgain()}
                >
                    Prøv igjen
                </S.TryAgainButton>
            </S.Description>
        );
    }

    return (
        <S.Wrapper>
            <h1>Feil</h1>
            <S.Description>
                {errorType
                    ? errorDataMap[errorType].description
                    : 'Det har oppstått en feil. Vennligst prøv igjen eller kontakt oss'}
            </S.Description>

            <S.TryAgainButton
                variant="secondary"
                onClick={() => handleTryAgain()}
            >
                Prøv igjen
            </S.TryAgainButton>
        </S.Wrapper>
    );
}
