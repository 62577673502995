import { css } from '@emotion/react';
import styled from '@emotion/styled';

export default {
    Wrapper: styled.div<{ variant: 'primary' }>`
        font-size: 16px;
        padding: 16px 23px;
        margin: 40px 0;

        ${(props) =>
            props.variant === 'primary' &&
            css`
                background-color: #c4a78c;
            `}
    `,
};
