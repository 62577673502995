import { Container } from '../container';
import S from './styles';

export function Header() {
    return (
        <S.Header>
            <Container>
                <S.Logo src="/logo.png" />
            </Container>
        </S.Header>
    );
}
