import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';

import { useStore } from '../../../../store/store';
import { ROUTES } from '../../../routes-map';
import { FormButton } from '../form-button';
import S from './styles';

export const BankIDMobilLoginForm = () => {
    const navigate = useNavigate();

    const { setGjeldsConsent, setUserData } = useStore((state) => ({
        setGjeldsConsent: state.setGjeldsConsent,
        setUserData: state.setUserData,
    }));

    const form = useForm<{
        personNumber: string;
        phoneNumber: string;
        gjeldsConsent: boolean;
    }>({
        defaultValues: {
            personNumber: '',
            phoneNumber: '',
            gjeldsConsent: false,
        },
    });

    const onSubmit = form.handleSubmit(async (values) => {
        try {
            setUserData({
                personNumber: values.personNumber,
                phoneNumber: values.phoneNumber,
            });

            setGjeldsConsent(values.gjeldsConsent);

            navigate(ROUTES.FINANCE_DATA_INIT.path);
        } catch (error) {
            console.error(error);
        }
    });

    return (
        <div>
            <S.Form onSubmit={onSubmit}>
                <S.FormItem>
                    <label htmlFor="personNumber">Fødselsnummer *</label>
                    <input
                        {...form.register('personNumber')}
                        type="tel"
                        maxLength={11}
                        autoCorrect="off"
                        spellCheck="false"
                        required
                    />
                </S.FormItem>
                <S.FormItem>
                    <label htmlFor="phoneNumber">Telefonnummer *</label>
                    <input
                        {...form.register('phoneNumber')}
                        pattern="[0-9]{8}"
                        maxLength={8}
                        required
                    />
                </S.FormItem>
                <FormControlLabel
                    label="Samtykke til deling av gjeldsinformasjon"
                    style={{ alignItems: '' }}
                    control={<Checkbox {...form.register('gjeldsConsent')} />}
                />
                <Box
                    style={{
                        fontSize: 14,
                        marginTop: -35,
                        paddingLeft: 30,
                    }}
                >
                    <p>
                        Jeg gir samtykke til at Eiendomsfinans AS, orgnr.
                        967692301 kan innhente mine gjeldsopplysninger fra
                        Gjeldsregisteret AS for å kunne gi privatøkonomisk
                        rådgivning.
                    </p>
                    <p>
                        Eiendomsfinans AS er behandlingsansvarlig for behandling
                        av personopplysninger i forbindelse med økonomisk
                        rådgivning, og som skal påse og påvise at opplysningene
                        behandles i samsvar med gjeldende personopplysningslov,
                        se personvernerklæring på
                        {` `}
                        <a
                            href="https://www.eiendomsfinans.no/personvern/"
                            target="_blank"
                        >
                            https://www.eiendomsfinans.no/personvern/
                        </a>
                        . Gjeldsopplysningene presenteres med samlet
                        innhold/felter og struktur i tråd med hvordan
                        opplysningene presenteres i Gjeldsregisteret sine
                        web-tjenester. Gjeldsopplysningene er levert av
                        Gjeldsregisteret AS. Oversikt over finansforetak som
                        leverer gjeldsopplysninger til Gjeldsregisteret AS
                        finner dere på{' '}
                        <a href="www.gjeldsregisteret.com" target="_blank">
                            gjeldsregisteret.com
                        </a>
                        .
                    </p>
                    <p>
                        Fullmakten er gyldig i 6 måneder. Samtykket ifm delte
                        gjeldsopplysninger kan når som helst trekkes hos{' '}
                        <a href="www.gjeldsregisteret.com" target="_blank">
                            www.gjeldsregisteret.com
                        </a>
                        .
                    </p>
                </Box>
                <FormButton
                    label="Fortsett"
                    type="submit"
                    disabled={
                        !form.watch('personNumber') ||
                        !form.watch('phoneNumber')
                    }
                />
            </S.Form>
        </div>
    );
};
