import styled from '@emotion/styled';

export default {
    Wrapper: styled.button`
        color: #fff;
        background-color: #4e73df;
        border-radius: 0.35rem;
        padding: 10px 12px;
        text-align: center;
        cursor: pointer;
        border: none;
        width: 100%;
        font-weight: bold;
        transition: all 250ms ease-in-out;

        &:hover {
            background-color: #2653d4;
        }

        :disabled {
            opacity: 0.55;
            pointer-events: none;
        }
    `,
};
