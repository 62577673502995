import { useEffect } from 'react';

import axios from 'axios';
import LogRocket from 'logrocket';

import { getAppEnvValue } from '../services/get-app-env-value';

export const useLogRocket = () => {
    useEffect(() => {
        const identityUser = async () => {
            try {
                const userIp = await axios
                    .get<{
                        country_code: string;
                        country_name: string;
                        city: string;
                        postal: string;
                        latitude: number;
                        longitude: number;
                        IPv4: string;
                        state: string;
                    }>('https://geolocation-db.com/json/')
                    .then((response) => response.data.IPv4);

                LogRocket.identify(userIp || 'user');
            } catch (error) {
                console.error(error);
            }
        };

        const logRocketId = getAppEnvValue('REACT_APP_LOGROCKET_ID') as string;

        if (logRocketId) {
            LogRocket.init(logRocketId);
            identityUser();
        }
    }, []);
};
