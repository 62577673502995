import { ReactNode } from 'react';

import S from './styles';

interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    children: ReactNode;
    variant: 'primary' | 'secondary';
}

export function Button({ variant, children, ...restProps }: ButtonProps) {
    return (
        <S.Wrapper variant={variant} {...restProps}>
            {children}
        </S.Wrapper>
    );
}
