import { useMutation } from 'react-query';

import { EindomfinansAPI } from '../../../api';

export const useExecuteProcess = () => {
    return useMutation(EindomfinansAPI.executeProcess);
};

export const useCheckProcessStatus = () => {
    return useMutation(EindomfinansAPI.checkProcessStatus);
};

export const useAuthUrl = () => {
    return useMutation(EindomfinansAPI.authUrl);
};
