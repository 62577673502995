import styled from '@emotion/styled';

export default {
    Wrapper: styled.div`
        margin: 30px 0;
    `,

    Type: styled.div`
        display: block;
        font-size: 20px;
        color: #858796;
        font-weight: 300;
    `,

    Names: styled.div`
        display: grid;
        grid-template-columns: 1fr;
        font-weight: 400;
        font-size: 16px;
    `,

    Name: styled.div``,
};
