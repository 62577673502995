import styled from '@emotion/styled';

export default {
    Header: styled.header`
        padding: 20px 0;
    `,

    Logo: styled.img`
        width: 100%;
        max-width: 196px;
        height: auto;
    `,
};
