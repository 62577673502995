import { useState } from 'react';

interface Props {
    items: Array<{ question: string; answer: string }>;
}

const FaqItem = ({
    question,
    answer,
}: {
    question: string;
    answer: string;
}) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="faq-item">
            <div
                className="faq-question"
                onClick={() => setIsOpen(!isOpen)}
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    cursor: 'pointer',
                }}
            >
                <span style={{ color: '#10307b' }}>{isOpen ? '▼' : '►'}</span>
                <span style={{ color: '#10307b' }}>{question}</span>
            </div>
            {isOpen && (
                <div
                    className="faq-answer"
                    style={{ fontSize: 15, padding: `0 26px` }}
                >
                    {answer}
                </div>
            )}
        </div>
    );
};

export const Faq: React.FC<Props> = ({ items }) => {
    return (
        <div
            className="faq"
            style={{
                margin: `24px 0`,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: '16px',
            }}
        >
            {items.map((item, index) => (
                <FaqItem key={index} {...item} />
            ))}
        </div>
    );
};
