import { QueryClient, QueryClientProvider } from 'react-query';

import { Global } from '@emotion/react';

import { useLogRocket } from './hooks/use-log-rocket';
import { AppRoutes } from './routes';
import { globalStyles } from './styles';

function App() {
    useLogRocket();

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient}>
            <Global styles={globalStyles} />
            <AppRoutes />
        </QueryClientProvider>
    );
}

export default App;
