import { useState } from 'react';

import { BankIDoginForm } from './components/bankid-login-form';

import { RegistryTypes } from './components/registry-types';
import S from './styles';
import { BankIDMobilLoginForm } from './components/bankid-mobil-login-form';

import { useStore } from '../../store/store';

export const LoginRoute = () => {
    const loginFlow = useStore((state) => state.loginFlow);

    const [isLogged, setIsLogged] = useState(false);

    return (
        <S.Wrapper>
            {!isLogged && (
                <>
                    <S.Heading>Autorisasjon</S.Heading>
                    <S.Subheading>
                        I løpet av prosessen samler vi inn informasjon fra
                        forskjellige kilder:
                    </S.Subheading>
                </>
            )}

            {loginFlow === 'BANKID' && !isLogged && <BankIDoginForm />}
            {loginFlow === 'BANKID_MOBILE' && (
                <>
                    <RegistryTypes
                        type="Offentlige registre"
                        names={['Skatteetaten', 'Folkeregisteret']}
                    />
                    <RegistryTypes
                        type="Private registre"
                        names={['Gjeldsregisteret']}
                    />
                    <BankIDMobilLoginForm />
                </>
            )}
        </S.Wrapper>
    );
};
