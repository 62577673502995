import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { ROUTES } from '../routes-map';
import S from './styles';

export const PreLoginBankIdMobil = () => {
    const navigate = useNavigate();

    return (
        <S.Wrapper>
            <S.Heading>Last opp dokumentasjon med Bank-ID Mobil</S.Heading>
            <p>
                For å raskt kunne behandle søknaden og utbetale lånet trenger vi
                og bankene vi samarbeider med dokumentasjon på søknaden din.
                Dataene vil ikke bli brukt til annet enn å dokumentere søknaden
                din, og blir behandlet i henhold til Eiendomsfinans sine
                personvernvilkår og brukervilkår.
            </p>
            <S.Subheading>Bank-ID på mobil:</S.Subheading>
            <p>
                Siden du har Bank-ID på mobil kan vi hente all dokumentasjonen
                automatisk
            </p>
            <ol>
                <li>
                    Da Logger du inn på Altinn/Skatteetaten gjennom vår tjeneste
                    med BankID på mobil for å identifisere deg selv
                </li>
                <li>
                    Vi samler inn siste selvangivelse og siste lønnsmeldinger
                    ved hjelp av BankID-innloggingen din. Data hentes fra Altinn
                    meldingsboks, Skatteetaten, Folkeregisteret, Eiendomsverdi
                    og Gjeldsregisteret og kan innhente informasjon fra
                    Inkassoregisteret om du ønsker.
                </li>
            </ol>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '20px 0',
                }}
            >
                <Button
                    variant="secondary"
                    onClick={() => navigate(ROUTES.LOGIN.path)}
                >
                    Fortsett
                </Button>
            </div>
        </S.Wrapper>
    );
};
