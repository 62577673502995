import { useParams } from 'react-router-dom';

import { routeParamsRecord } from '../routes/routes-map';

export const useProcessIdParam = () => {
    const params = useParams();
    const processId = params[routeParamsRecord.processId] || '';

    return { processId };
};
