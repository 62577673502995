export type GetaAppEnvValueType = string | number | object | null | undefined;

declare global {
    interface Window {
        config: { [key: string]: GetaAppEnvValueType };
    }
}

export function getAppEnvValue(parameter: string): GetaAppEnvValueType {
    try {
        switch (parameter) {
            case 'REACT_APP_ONESCORE_API_URL':
                return (
                    process.env.REACT_APP_ONESCORE_API_URL ||
                    window.config?.[parameter]
                );
            case 'REACT_APP_LOGROCKET_ID':
                return (
                    process.env.REACT_APP_LOGROCKET_ID ||
                    window.config?.[parameter]
                );
            default:
                return '';
        }
    } catch (e) {
        return window.config?.[parameter];
    }
}
