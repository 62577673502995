import { useStore } from '../../store/store';

import { useNavigate } from 'react-router-dom';

import { SubmitEmailRoute } from '../submit-email';
import { SubmitCRMRoute } from '../submit-crm';

export const FinanceDataSubmitRoute = () => {
    // const navigate = useNavigate();

    const sendingFlow = useStore((state) => state.sendingFlow);

    if (sendingFlow === 'EMAIL') {
        return <SubmitEmailRoute />;
    }

    if (sendingFlow === 'CRM') {
        return <SubmitCRMRoute />;
    }

    return null;
};
