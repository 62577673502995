import styled from '@emotion/styled';

export default {
    Wrapper: styled.section`
        color: #858796;
        line-height: 1.5;
        max-width: 440px;
        margin: 0 auto;
        padding: 60px 0;
    `,

    Heading: styled.h2`
        font-size: 28px;
        text-align: center;
        margin: 0 0 10px;
    `,

    Subheading: styled.div`
        font-size: 16px;
        text-align: center;
        margin: 0 0 10px;
    `,

    LoginMethodsHeading: styled.h3`
        margin: 0 0 10px;
        text-align: center;
    `,

    Abort: styled.div`
        text-align: center;
        cursor: pointer;
        color: #858796;
        font-size: 14px;
        margin: 8px 0 0 0;

        &:hover {
            text-decoration: underline;
        }
    `,
};
