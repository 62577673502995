import { PreLoginBankId } from './pre-login-bankid';
import { PreLoginBankIdMobil } from './pre-login-bankid-mobil';
import S from './styles';

import { useStore } from '../../store/store';

export const PreLoginRoute = () => {
    const loginFlow = useStore((state) => state.loginFlow);

    if (loginFlow === 'BANKID') {
        return <PreLoginBankId />;
    }

    if (loginFlow === 'BANKID_MOBILE') {
        return <PreLoginBankIdMobil />;
    }

    return <S.Wrapper>Error: unsupported flow ({loginFlow})</S.Wrapper>;
};
