import { useCallback, useState } from 'react';

import { ProcessStatus } from '../../../api';
import { useEffectOnce } from '../../../hooks/use-effect-once';
import { useProcessIdParam } from '../../../hooks/use-process-id-param';
import { useCheckRequestKeyMutation } from '../../../queries/useCheckRequestKeyMutation';
import { useForwardDocumentsMutation } from '../../../queries/useForwardDocumentsMutation';
import { useStore } from '../../../store/store';

export const useForwardDocuments = () => {
    const destinationKey = useStore((state) => state.destinationKey);

    const { processId } = useProcessIdParam();

    const [requestKeyState, setRequestKeyState] =
        useState<ProcessStatus>('Created');

    const forwardDocumentsMutation = useForwardDocumentsMutation();
    const checkRequestKeyMutation = useCheckRequestKeyMutation();

    const isLoading = requestKeyState === 'Created';

    const isError =
        forwardDocumentsMutation.isError ||
        requestKeyState === 'Closed' ||
        requestKeyState === 'NotFound' ||
        requestKeyState === 'Unknown';

    const forwardDocuments = useCallback(async () => {
        try {
            await forwardDocumentsMutation.mutateAsync({
                data: {
                    processId,
                    requestKey: destinationKey,
                },
            });
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffectOnce(() => {
        if (requestKeyState === 'Created' && forwardDocumentsMutation.isIdle) {
            forwardDocuments();
        }

        /**
         * Check every 5 seconds if the backend has processed the request key.
         */
        const checkRequestKeyInterval = setInterval(async () => {
            const { state } = await checkRequestKeyMutation.mutateAsync({
                requestKey: destinationKey,
            });

            if (state === 'Finished') {
                setRequestKeyState(state);
                clearInterval(checkRequestKeyInterval);
            }

            if (
                state === 'Closed' ||
                state === 'NotFound' ||
                state === 'Unknown'
            ) {
                setRequestKeyState(state);
                clearInterval(checkRequestKeyInterval);
            }
        }, 5000);

        /**
         * If the backend does not respond within 10 minutes with the proper
         * state then display an error.
         */
        setTimeout(() => {
            clearInterval(checkRequestKeyInterval);
            setRequestKeyState('Unknown');
        }, 600000);
        /**
         * Cleanup interval on unmount.
         */
        return () => clearInterval(checkRequestKeyInterval);
    });

    return { isLoading, isError };
};
