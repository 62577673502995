import { Box } from '../../components/box';
import { Button } from '../../components/button';
import { ErrorBox } from '../../components/error-box';
import { useSubmitEmail } from './hooks/use-submit-email';

export function SubmitEmailRoute() {
    const { state } = useSubmitEmail();

    if (state === 'loading') {
        return (
            <Box variant="primary">
                <h1>Autorisasjon</h1>
                Vi sjekker om vi kan få dine data fra offentlige registre. Det
                tar vanligvis 5-10 sekunder. Vennligst vent.
                <p>...</p>
            </Box>
        );
    }

    if (state === 'error') {
        return (
            <ErrorBox>
                <a href="https://www.eiendomsfinans.no/">
                    <Button variant="primary">Avslutt</Button>
                </a>
            </ErrorBox>
        );
    }

    if (state === 'success') {
        return (
            <Box variant="primary">
                <h1>Autorisasjonen var vellykket</h1>
                <p>
                    Dine data blir sendt din rådgiver, som vil ta kontakt med
                    deg!
                </p>
                <a href="https://www.eiendomsfinans.no/">
                    <Button variant="primary">Avslutt</Button>
                </a>
            </Box>
        );
    }

    return null;
}
