import styled from '@emotion/styled';

export default {
    Wrapper: styled.div<{ size?: number }>`
        max-width: ${(props) => (props.size ? props.size : '860')}px;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
    `,
};
