import styled from '@emotion/styled';

export default {
    Wrapper: styled.div`
        margin-top: 30px;

        .loader-ring {
            display: inline-block;
            position: relative;
            width: 32px;
            height: 32px;
        }

        .loader-ring div {
            box-sizing: border-box;
            display: block;
            position: absolute;
            width: 32px;
            height: 32px;
            margin: 3px;
            border: 3px solid #000;
            border-radius: 50%;
            animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            border-color: #000 transparent transparent transparent;
        }

        .loader-ring div:nth-child(1) {
            animation-delay: -0.45s;
        }

        .loader-ring div:nth-child(2) {
            animation-delay: -0.3s;
        }

        .loader-ring div:nth-child(3) {
            animation-delay: -0.15s;
        }

        @keyframes loader-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    `,
};
