import { ReactNode } from 'react';

import { Box } from '../box';

interface ErrorBoxProps {
    children: ReactNode;
}
export function ErrorBox({ children }: ErrorBoxProps) {
    return (
        <Box variant="primary">
            <h1>Noe gikk galt</h1>
            <p>
                Det er mulig at det er noe galt med tjenesten vår eller at det
                ikke finnes data på deg i databasen.{' '}
            </p>
            <p>
                Du kan gjerne prøve igjen med en annen browser, men det kan se
                ut til at du selv bør logge inn på Skatteetaten.no og laste ned
                din siste selvangivelse, og videresende denne til din rådgiver i
                Eiendomsfinans.
            </p>
            <p>
                Vi trenger også kopi av de siste tre lønnsslippene. Det
                vanligste er at du har fått en link til de i en epost fra
                arbeidsgiver.
            </p>

            {children}
        </Box>
    );
}
