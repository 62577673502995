import styled from '@emotion/styled';

import { Button } from '../../components/button';

export default {
    Wrapper: styled.section`
        display: grid;
        gap: 30px;
        text-align: center;
        padding: 60px 0;
    `,

    Description: styled.div``,

    TryAgainButton: styled(Button)`
        justify-self: center;
        font-size: 16px;
    `,
};
