import { useNavigate } from 'react-router-dom';

import { Button } from '../../components/button';
import { useSubmissionMode } from '../../hooks/use-submission-mode';
import { useTargetIdParam } from '../../hooks/use-target-id-param';
import { useStore } from '../../store/store';
import { LoginFlow } from '../../utils/types';
import { ROUTES } from '../routes-map';
import { useMatchEmailDestination } from '../submit-email/hooks/use-match-email-destination';

export function HomeRoute() {
    const { targetId } = useTargetIdParam();

    const navigate = useNavigate();

    const { submissionMode } = useSubmissionMode();

    const { emailDestination } = useMatchEmailDestination();

    const { reset, setDestinationKey, setLoginFlow, setSendingFlow } = useStore(
        (state) => ({
            reset: state.reset,
            setLoginFlow: state.setLoginFlow,
            setDestinationKey: state.setDestinationKey,
            setSendingFlow: state.setSendingFlow,
        }),
    );

    const selectFlow = (loginFlow: LoginFlow) => {
        reset();
        setLoginFlow(loginFlow);

        if (submissionMode === 'EMAIL') {
            const email = emailDestination(targetId);
            setDestinationKey(email);
        } else {
            setDestinationKey(targetId);
        }

        setSendingFlow(submissionMode);

        navigate(
            ROUTES.PRE_LOGIN.buildPath({
                targetId,
                loginFlow,
            }),
        );
    };

    return (
        <>
            <h1>Last opp dokumentasjon</h1>
            <p>
                For å raskt kunne behandle søknaden og utbetale lånet trenger vi
                og bankene vi samarbeider med dokumentasjon på søknaden din.
                Dataene vil ikke bli brukt til annet enn å dokumentere søknaden
                din, og blir behandlet i henhold til Eiendomsfinans sine
                personvernvilkår og brukervilkår
            </p>
            <div>
                {/* This section was commented out on the FE side, because it is no longer valid, BE part still exists. */}
                {/* <h3>Bank-ID på mobil</h3>
                <p>
                    Hvis du har Bank-ID på mobil kan vi hente all
                    dokumentasjonen vi trenger automatisk
                </p>
                <div style={{ textAlign: 'center', margin: '0 0 60px' }}>
                    <Button
                        variant="secondary"
                        onClick={() => selectFlow('BANKID_MOBILE')}
                    >
                        Fortsett med Bank-ID Mobil
                    </Button>
                </div> */}
                <h3>Annen Bank-ID</h3>
                <p>
                    Har du Bank-ID med kodebrikke eller app kan vi hente noen
                    data automatisk, og vi kan veilede deg om hvordan du lett
                    kan finne den andre informasjonen vi trenger:
                </p>
                <p>
                    Denne løsningen fungerer best på en datamaskin, men kan
                    påbegynnes på mobil. Sesjonen vil være åpen i 1t for å
                    fullføre prosessen, og vi anbefaler alle at dette primært
                    gjøres på en datamaskin.
                </p>
                <div style={{ textAlign: 'center', margin: '0 0 60px' }}>
                    <Button
                        variant="secondary"
                        onClick={() => selectFlow('BANKID')}
                    >
                        Fortsett med Bank-ID
                    </Button>
                </div>
            </div>
        </>
    );
}
