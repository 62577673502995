import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { media } from '../../styles/media';

export default {
    Wrapper: styled.button<{ variant: 'primary' | 'secondary' }>`
        border: none;
        background: none;
        cursor: pointer;
        padding: 9px 13px;

        ${media.max('md')} {
            font-size: 16px;
        }

        ${media.min('md')} {
            font-size: 20px;
        }

        ${(props) =>
            props.variant === 'primary' &&
            css`
                background-color: #ffffff;
                color: #000000;
            `}

        ${(props) =>
            props.variant === 'secondary' &&
            css`
                background-color: #c4a78c;
                color: #000000;
            `}
    `,
};
