import { route, string } from 'react-router-typesafe-routes/dom'; // Or /native

export const routeParamsRecord = {
    target: `targetId`,
    error: `errorType`,
    processId: `processId`,
    flow: `loginFlow`,
    code: `code`,
    submissionMode: `submissionMode`,
    requestKey: `requestKey`,
    refererRoute: `refererRoute`,
} as const;

export const ROUTES = {
    HOME: route(``),
    HOME_WITH_TARGET: route(`:${routeParamsRecord.target}`, {
        params: { targetId: string().defined() },
    }),
    PRE_LOGIN: route(`pre-authentication`),
    LOGIN: route(`authentication`),
    FINANCE_DATA_INIT: route(`finance/data/init`),
    FINANCE_DATA_UPLOAD: route(
        `finance/data/upload/process/:${routeParamsRecord.processId}`,
        {
            params: {
                [routeParamsRecord.processId]: string().defined(),
            },
            searchParams: {
                [routeParamsRecord.submissionMode]: string(),
                [routeParamsRecord.requestKey]: string(),
                [routeParamsRecord.refererRoute]: string(),
            },
        },
    ),
    FINANCE_DATA_SUBMIT: route(
        `finance/data/submit/process/:${routeParamsRecord.processId}`,
        {
            params: {
                [routeParamsRecord.processId]: string().defined(),
            },
        },
    ),
    VERIFICATION: route(`verification`),
    ERROR: route(`target/:${routeParamsRecord.target}/error`, {
        params: { targetId: string().defined() },
    }),
    ERROR_WITH_TYPE: route(
        `target/:${routeParamsRecord.target}/error/:${routeParamsRecord.error}`,
        {
            params: {
                [routeParamsRecord.target]: string().defined(),
                [routeParamsRecord.error]: string().defined(),
            },
        },
    ),
};
