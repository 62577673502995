import { ReactNode } from 'react';

import S from './styles';

interface ContainerProps {
    children: ReactNode;
    size?: number;
}

export function Container({ children, size }: ContainerProps) {
    return <S.Wrapper size={size}>{children}</S.Wrapper>;
}
