import { useEffect, useState } from 'react';

import { useTypedParams } from 'react-router-typesafe-routes/dom';

import { useCheckUploadedFiles } from '../../queries/use-check-uploaded-files';
import { ROUTES } from '../routes-map';
import { DocumentType } from './components/document-type-select/document-type-select';

export const useUploadedFiles = () => {
    const { processId } = useTypedParams(ROUTES.FINANCE_DATA_UPLOAD);
    const checkUploadedFiles = useCheckUploadedFiles();

    const [uploadedFiles, setUploadedFiles] = useState<
        Array<{
            name: string;
            type: DocumentType;
        }>
    >([]);

    useEffect(() => {
        const check = async () => {
            const response = await checkUploadedFiles.mutateAsync({
                processID: processId,
            });

            setUploadedFiles(
                response.documents.map((item) => ({
                    name: item.s3Key.split('/')[1],
                    type: item.documentType,
                })),
            );
        };

        check();
    }, []);

    const addUploadedFile = (file: File, fileType: DocumentType) => {
        setUploadedFiles((uploadedFiles) => [
            ...uploadedFiles,
            {
                name: file.name,
                type: fileType,
            },
        ]);
    };

    const isSkattemeldingUploaded = uploadedFiles.some(
        (file) => file.type === 'skattemelding',
    );

    return {
        uploadedFiles,
        setUploadedFiles,
        addUploadedFile,
        isSkattemeldingUploaded,
    };
};
