import S from './styles';

interface Props {
    type: string;
    names: Array<string>;
}

export const RegistryTypes = ({ type, names }: Props) => {
    return (
        <S.Wrapper>
            <S.Type>{type}:</S.Type>
            <S.Names>
                {names.map((item, index) => (
                    <S.Name key={index}>{item}</S.Name>
                ))}
            </S.Names>
        </S.Wrapper>
    );
};
