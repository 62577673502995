import { useParams, useSearchParams } from 'react-router-dom';

import { routeParamsRecord } from '../routes/routes-map';

/**
 * Read the targetId from the URL
 * @example http://example.com/secretuser -> secretuser
 *
 * @returns {Object} targetIdParam
 * @returns {string} targetIdParam.targetId - The `targetId` from the URL,
 * or `default` if the targetId is missing.
 * @returns {boolean} targetIdParam.isTargetIdUUID - `true` if the targetId
 * is a valid UUID,
 */
export const useCodeParam = () => {
    let [searchParams, _] = useSearchParams();

    const code = searchParams.get('code') || '';

    return { code };
};
