import { useParams } from 'react-router-dom';
import { validate } from 'uuid';

import { routeParamsRecord } from '../routes/routes-map';

/**
 * Read the targetId from the URL
 * @example http://example.com/secretuser -> secretuser
 *
 * @returns {Object} targetIdParam
 * @returns {string} targetIdParam.targetId - The `targetId` from the URL,
 * or `default` if the targetId is missing.
 * @returns {boolean} targetIdParam.isTargetIdUUID - `true` if the targetId
 * is a valid UUID,
 */
export const useTargetIdParam = () => {
    const params = useParams();
    const targetId = params[routeParamsRecord.target];

    const isTargetIdUUID = targetId ? validate(targetId) : false;

    return { targetId: targetId || 'default', isTargetIdUUID };
};
